import type { NextPage } from 'next';
import Head from 'next/head';
import Landing from '../components/Landing';

type LandingPageProps = {};

const LandingPage: NextPage<LandingPageProps> = () => {
  return (
    <>
      <Head>
        <title>NYALA Asset Management</title>
      </Head>
      <Landing />
    </>
  );
};

export default LandingPage;
